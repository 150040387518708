import {isNil} from 'lodash';

export interface PortfolioAccount {
  id: string;
  portfolioId: string;
  cashIban: string;
  customerId: string;
  depositaryAccount: string;
  originIban: string;
  originIbanLastFourChars: number;
  externalId: string;
  activatedAt: string | null;
}

export const getMaskedIban = (originIbanLastFourChars): string => '** **** **** **** **** ' + originIbanLastFourChars;
export const hasOriginIban = (account:  PortfolioAccount): boolean => !isNil(account?.originIban);
export const hasAccountBeenActivated = (account: PortfolioAccount): boolean => !isNil(account.activatedAt);
