import {camelCase} from 'lodash';
import {BackofficePortfolio} from 'src/app/domain/model/backoffice-portfolio/backoffice-portfolio.model';

export enum InvestmentStrategyName {
  global = 'global',
  monetary =  'monetary',
  usaPlus = 'usa+',
  internationalReit = 'International Reit'
}

export const STRATEGY_NAME_SPECIAL_CASES_MAP: Record<string, string> = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'usa+': 'usaPlus',
};

export enum InvestmentStrategyIcon {
  global = 'earth-outline',
  monetary =  'cellular-outline',
  usaPlus = 'assets/icons/usa-flag.svg',
  internationalReit = 'business-outline'
}

export enum InvestmentStrategyCategories {
  mediumLongTerm = 'Medium-Long Term',
  liquidityRemuneration = 'Liquidity remuneration',
}

export interface InvestmentStrategy {
  id: string;
  name: InvestmentStrategyName;
  category: string;
  order: number;
}

export interface InvestmentStrategyGroup {
  investmentStrategy: InvestmentStrategy;
  minTolerance: number;
  boPortfolios: BackofficePortfolio[];
}

export interface InvestmentStrategyGroupsByCategory {
  category: string;
  strategyGroups: InvestmentStrategyGroup[];
}

export interface InvestmentStrategyGroupsByProduct {
  productId: string;
  strategyGroups: InvestmentStrategyGroup[];
}

export const getNormalizedStrategyName = (strategyName: string): string => {
  if (!strategyName) {
    return InvestmentStrategyName.global;
  }

  const lowerName = strategyName.toLowerCase();
  return STRATEGY_NAME_SPECIAL_CASES_MAP[lowerName] || camelCase(lowerName);
};

export const isValidStrategyName = (strategyName: string): boolean =>
  Object.values(InvestmentStrategyName).includes(strategyName as InvestmentStrategyName);

export const isAssetIcon = (strategyName: string): boolean =>
  getStrategyIconByName(strategyName).includes('assets/icons');

export const getStrategyIconByName = (strategyName: string): string => {
  const name = getNormalizedStrategyName(strategyName);
  return InvestmentStrategyIcon[name];
};

export const isRecommended = (investmentStrategy: InvestmentStrategy) => {
  const currentStrategyName = investmentStrategy.name.toLowerCase();
  return currentStrategyName === InvestmentStrategyName.global || currentStrategyName === InvestmentStrategyName.monetary;
};

export const getInvestmentStrategyCategoryTranslationKey = (category: string): string =>
  `investmentStrategy.categories.${category.toLowerCase()}.title`;

export const getInvestmentStrategyNameTitleTranslationKey = (categoryName: string): string => {
  const name = getNormalizedStrategyName(categoryName);
  return `investmentStrategy.info.${name}.title`;
};

export const getInvestmentStrategyNameTranslationKey = (categoryName: string): string => {
  const name = getNormalizedStrategyName(categoryName);
  return `investmentStrategy.info.${name}.name`;
};

export const getInvestmentStrategyGroupFullNameTranslationKey = (investmentStrategyGroup: InvestmentStrategyGroup): string => {
  const name = getNormalizedStrategyName(investmentStrategyGroup.investmentStrategy.name);
  return `investmentStrategy.info.${name}.fullName`;
};

export const getInvestmentStrategyChecks = (investmentStrategy: InvestmentStrategy): string[] => {
  const nameKey = getNormalizedStrategyName(investmentStrategy.name);
  const nameValue = InvestmentStrategyName[nameKey];

  switch (nameValue) {
    case InvestmentStrategyName.global:
    case InvestmentStrategyName.monetary:
    case InvestmentStrategyName.usaPlus:
    case InvestmentStrategyName.internationalReit:
      return [
        `investmentStrategy.select.info.${nameKey}.first`,
        `investmentStrategy.select.info.${nameKey}.second`,
        `investmentStrategy.select.info.${nameKey}.third`,
      ];
    default:
      return [];
  }
};

export const groupBoPortfoliosByInvestmentStrategy = (boPortfolios: BackofficePortfolio[]): InvestmentStrategyGroup[] => {
  const investmentStrategyGroups: InvestmentStrategyGroup[] = [];

  boPortfolios.forEach((boPortfolio) => {
    const existingGroup = investmentStrategyGroups.find((group) => group.investmentStrategy.id === boPortfolio.investmentStrategy.id);
    if (existingGroup) {
      existingGroup.boPortfolios.push(boPortfolio);
      if (boPortfolio.tolerance < existingGroup.minTolerance) {
        existingGroup.minTolerance = boPortfolio.tolerance;
      }
    } else {
      investmentStrategyGroups.push({
        investmentStrategy: boPortfolio.investmentStrategy,
        minTolerance: boPortfolio.tolerance,
        boPortfolios: [boPortfolio]
      });
    }
  });

  return investmentStrategyGroups.sort((a, b) => a.investmentStrategy.order - b.investmentStrategy.order);
};

export const groupBoPortfoliosByStrategyCategories = (boPortfolios: BackofficePortfolio[]): InvestmentStrategyGroupsByCategory[] => {
  const investmentStrategyGroups = groupBoPortfoliosByInvestmentStrategy(boPortfolios);
  const investmentStrategyGroupsByCategory: InvestmentStrategyGroupsByCategory[] = [];

  investmentStrategyGroups.forEach((group) => {
    const existingCategoryGroup = investmentStrategyGroupsByCategory
      .find((categoryGroup) => categoryGroup.category === group.investmentStrategy.category);

    if (existingCategoryGroup) {
      existingCategoryGroup.strategyGroups.push(group);
    } else {
      investmentStrategyGroupsByCategory.push({
        category: group.investmentStrategy.category,
        strategyGroups: [group]
      });
    }
  });

  return investmentStrategyGroupsByCategory;
};

export const groupStrategiesByProductId = (boPortfolios: BackofficePortfolio[]): InvestmentStrategyGroupsByProduct[] => {
  const strategyGroupsByProduct: InvestmentStrategyGroupsByProduct[] = [];

  boPortfolios.forEach((boPortfolio) => {
    const existingProductGroup = strategyGroupsByProduct.find(
      (productGroup) => productGroup.productId === boPortfolio.productId
    );

    if (existingProductGroup) {
      const existingStrategyGroup = existingProductGroup.strategyGroups.find(
        (strategyGroup) => strategyGroup.investmentStrategy.id === boPortfolio.investmentStrategy.id
      );

      if (existingStrategyGroup) {
        existingStrategyGroup.boPortfolios.push(boPortfolio);
        if (boPortfolio.tolerance < existingStrategyGroup.minTolerance) {
          existingStrategyGroup.minTolerance = boPortfolio.tolerance;
        }
      } else {
        existingProductGroup.strategyGroups.push({
          investmentStrategy: boPortfolio.investmentStrategy,
          minTolerance: boPortfolio.tolerance,
          boPortfolios: [boPortfolio]
        });
      }
    } else {
      strategyGroupsByProduct.push({
        productId: boPortfolio.productId,
        strategyGroups: [{
          investmentStrategy: boPortfolio.investmentStrategy,
          minTolerance: boPortfolio.tolerance,
          boPortfolios: [boPortfolio]
        }]
      });
    }
  });

  strategyGroupsByProduct.forEach((productGroup) => {
    productGroup.strategyGroups.sort((a, b) => a.investmentStrategy.order - b.investmentStrategy.order);
  });

  return strategyGroupsByProduct;
};
